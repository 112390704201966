import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import { graphql } from 'gatsby';
import LawPage from '../components/LawPage';

const LawCriminalPage: React.FC = () => (
  <Layout id="lawCriminal">
    <SEO title="CriminalLaw" />
    <LawPage
      law="LawCriminal"
      title="fieldLawCriminal"
      serviceTitle="fieldLawCriminalTitle"
      lawTexts={[
        'fieldLawCriminalOne',
        'fieldLawCriminalTwo',
        'fieldLawCriminalThree',
        'fieldLawCriminalFour',
        'fieldLawCriminalFive',
        'fieldLawCriminalSix',
      ]}
    />
  </Layout>
);

export default LawCriminalPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
